import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useOrganisationsList() {
  // Use toast
  const toast = useToast()

  const refOrganisationListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      label: '#', key: 'id', sortable: true, tdClass: 'organisation_id',
    },
    {
      label: 'Naam', key: 'name', sortable: true, tdClass: 'organisation_id',
    },
    {
      label: 'Telefoon', key: 'phoneNumber', sortable: true, tdClass: 'phone',
    },
    {
      label: 'Aanmaakdatum', key: 'createTimestamp', sortable: true, tdClass: 'organisation_createdDate',
    },
    {
      label: 'Acties', key: 'actions', tdClass: 'actions',
    },
  ]
  const perPage = ref(10)
  const totalOrganisations = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const showOverlay = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refOrganisationListTable.value ? refOrganisationListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalOrganisations.value,
    }
  })

  const refetchData = () => {
    refOrganisationListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchOrganisations = (ctx, callback) => {
    store
      .dispatch('app-organisations/fetchOrganisations', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortDesc: isSortDirDesc.value,
        sortBy: sortBy.value,
      })
      .then(response => {
        const { items, _meta } = response.data

        callback(items)
        showOverlay.value = false
        totalOrganisations.value = _meta.totalCount
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching organisations list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const statusOptions = [
    { label: 'Actief', value: 1 },
    { label: 'Inactief', value: 0 },
  ]

  const statusAddOptions = [
    { label: 'Inactief', value: 0 },
  ]

  const countryOptions = [
    { label: 'Suriname', value: 'SR' },
  ]

  const districts = [
    {
      label: 'SR',
      value: [
        {
          label: 'Brokopondo',
          value: 'Brokopondo',
        },
        {
          label: 'Commewijne',
          value: 'Commewijne',
        },
        {
          label: 'Coronie',
          value: 'Coronie',
        },
        {
          label: 'Marowijne',
          value: 'Marowijne',
        },
        {
          label: 'Nickerie',
          value: 'Nickerie',
        },
        {
          label: 'Para',
          value: 'Para',
        },
        {
          label: 'Paramaribo',
          value: 'Paramaribo',
        },
        {
          label: 'Saramacca',
          value: 'Saramacca',
        },
        {
          label: 'Sipaliwini',
          value: 'Sipaliwini',
        },
        {
          label: 'Wanica',
          value: 'Wanica',
        },
      ],
    },
  ]

  // Change district on different country select
  const resolveDistrictForCountry = countryCode => districts.filter(arr => arr.label === countryCode)[0].value

  // Initial set districts
  const districtOptions = resolveDistrictForCountry('SR')

  const resolveUserStatus = status => {
    if (status === 0) return 'Inactief'
    if (status === 1) return 'Actief'
    return 'Actief'
  }

  const resolveUserStatusVariant = status => {
    if (status === 0) return 'warning'
    if (status === 1) return 'success'
    return 'primary'
  }

  const typeOptions = [
    { label: 'Hoofdorganisatie', value: 1 },
    { label: 'Suborganisatie', value: 2 },
  ]

  const externalFinanceTypeOptions = [
    { label: 'Bank', value: '1' },
    { label: 'Ministerie', value: '2' },
    { label: 'Parastataal', value: '3' },
    { label: 'Overige', value: '4' },
    { label: 'Innende agenten Nickerie', value: '5' },
    { label: 'Overige innende agenten', value: '6' },
    { label: 'Agent inwording', value: '7' },
  ]

  return {
    fetchOrganisations,
    tableColumns,
    perPage,
    currentPage,
    totalOrganisations,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    refOrganisationListTable,
    resolveUserStatus,
    resolveUserStatusVariant,
    resolveDistrictForCountry,
    refetchData,

    // Opties
    statusOptions,
    statusAddOptions,
    countryOptions,
    districtOptions,
    typeOptions,
    isSortDirDesc,
    showOverlay,
    externalFinanceTypeOptions,
  }
}
